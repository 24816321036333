import ApiService from "@/common/api/api.service";

const AnoLetivoService = {
    async pegarTodos() {
        let { data } = await ApiService.get("AnoLetivo");
        return data;
    },
    async listar(paginacao) {
        let { data } = await ApiService.get(`AnoLetivo`, { params: paginacao });
        return data;
    },
    async buscarPorId(id) {
        let { data } = await ApiService.get(`AnoLetivo/${id}`);
        return data;
    },
    async buscarPorIdComSegmentacao(id) {
        let { data } = await ApiService.get(
            `AnoLetivo/obter-lista-segmentacoes`,
            { params: { id } }
        );
        return data;
    },
};

export default AnoLetivoService;
