<template>
    <div>
        <div>
            <div class="accordion" role="tablist">
                <b-card
                    no-body
                    v-for="item in contasAReceber"
                    v-bind:key="item.id"
                >
                    <b-card-header header-tag="header" class="p-1" role="tab">
                        <b-button
                            block
                            v-b-toggle="item.id"
                            variant="outline-primary"
                            class="text-left"
                        >
                            <div class="d-grid grid-cols-3">
                                <span>
                                    {{ item.historicoDescricao }}
                                </span>
                                -
                                <span>
                                    {{ formatarValor(item.valor) }}
                                </span>
                            </div>
                        </b-button>
                    </b-card-header>
                    <b-collapse
                        :id="item.id"
                        accordion="lista-contas-a-receber"
                        role="tabpanel"
                    >
                        <div class="bg-gray p-2">
                            <b-table
                                head-variant="light"
                                :empty-text="$t('GERAL.NENHUM_REGISTRO')"
                                :items="item.titulosOcorrencias"
                                :fields="camposParcelas"
                                show-empty
                                responsive
                                striped
                                hover
                            />
                        </div>
                    </b-collapse>
                </b-card>
            </div>
            <div v-if="contasAReceber.length === 0" class="text-center">
                <span>{{ $t('FINANCEIRO.SEM_DADOS_PARA_LISTAR') }}</span>
            </div>
        </div>
    </div>
</template>

<script>
// Aux & helps
import helpers from '@/common/utils/helpers';

export default {
    props: {
        contasAReceber: { type: Array, default: Array },
    },
    data() {
        return {
            campos: [
                {
                    key: 'dataLancamento',
                    label: this.$t('ACADEMICO.DATA_LANCAMENTO'),
                    formatter: (value) => {
                        return value ? helpers.formatarDataBr(value) : '-';
                    },
                },
                {
                    key: 'valor',
                    label: this.$t('ACADEMICO.VALOR'),
                    formatter: (value) => {
                        return value ? helpers.formatarReal(value) : '-';
                    },
                },
                {
                    key: 'statusPagamentoDescricao',
                    label: this.$t('ACADEMICO.STATUS'),
                    formatter: (value) => {
                        return value ? value : '-';
                    },
                },
            ],
            camposParcelas: [
                {
                    key: 'dataVencimento',
                    label: this.$t('ACADEMICO.DATA_VENCIMENTO'),
                    formatter: (value) => {
                        return value ? helpers.formatarDataBr(value) : '-';
                    },
                },
                {
                    key: 'valor',
                    label: this.$t('ACADEMICO.VALOR'),
                    formatter: (value) => {
                        return value ? helpers.formatarReal(value) : '-';
                    },
                },
            ],
        };
    },
    methods: {
        formatarData(data) {
            return helpers.formatarDataBr(data);
        },
        formatarValor(valor) {
            return helpers.formatarReal(valor);
        },
    },
};
</script>
