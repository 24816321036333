<template>
    <b-row align-v="end">
        <b-col cols="3">
            <input-text
                :disabled="true"
                v-model="dados.codigoMatricula"
                ref="codigoMatricula"
                :label="$t('FINANCEIRO.MATRICULA')"
                required
            />
        </b-col>

        <b-col cols="3">
            <input-text
                :disabled="true"
                v-model="dados.nomeAluno"
                ref="aluno"
                :label="$t('FINANCEIRO.ALUNO')"
                required
            />
        </b-col>
        <b-col cols="3">
            <input-text
                :disabled="true"
                v-model="dados.codigoContrato"
                ref="codigoContrato"
                :label="'Código contrato'"
                required
            />
        </b-col>
        <b-col cols="3">
            <input-select-search
                :isDisabled="true"
                ref="dados.tipoContrato"
                :options="opcoes.tipoContratos"
                v-model="dados.tipoContrato"
                :label="$t('FINANCEIRO.TIPO_CONTRATO')"
                required
            />
        </b-col>
        <b-col cols="3">
            <input-select
                :disabled="true"
                ref="dados.anoLetivoId"
                :options="opcoes.anosLetivos"
                v-model="dados.anoLetivoId"
                :label="$t('FINANCEIRO.ANO_LETIVO')"
                valueField="id"
                textField="ano"
                required
            />
        </b-col>
        <b-col cols="3">
            <input-select-search
                :isDisabled="true"
                ref="dados.cursoId"
                :options="opcoes.cursos"
                v-model="dados.cursoId"
                :label="$t('FINANCEIRO.CURSO')"
                required
            />
        </b-col>
        <b-col cols="3">
            <input-select
                ref="dados.grupoTurmaId"
                :options="opcoes.grupoTurmas"
                v-model="dados.grupoTurmaId"
                :label="$t('FINANCEIRO.GRUPO_TURMA')"
                valueField="id"
                textField="descricao"
                :disabled="true"
                required
            />
        </b-col>
        <b-col cols="3">
            <input-select-search
                :isDisabled="true"
                ref="dados.idioma"
                :options="opcoes.idiomas"
                v-model="dados.idioma"
                :label="$t('FINANCEIRO.IDIOMA')"
            />
        </b-col>
        <b-col cols="3">
            <input-text
                :disabled="true"
                :label="$t('FINANCEIRO.STATUS_CONTRATO')"
                v-model="dados.statusDescricao"
            />
        </b-col>
    </b-row>
</template>

<script>
// Utils & Helps
import mensagem from '@/common/utils/mensagem';
import { START_LOADING, STOP_LOADING } from '@/store/Store';

// services
import CursoService from '@/common/services/academico/curso.service';
import AnoLetivoService from '@/common/services/ano-letivo/ano-letivo.service';
import GrupoTurmaService from '@/common/services/grupo-turma/grupo-turma.service';
import EnumeradoresService from '@/common/services/enumeradores/enumeradores.service';

// Components:
import { InputSelect, InputSelectSearch, InputText } from '@/components/inputs';

export default {
    name: 'DadosGerais',
    components: {
        InputSelect,
        InputSelectSearch,
        InputText,
    },
    props: {
        dados: { type: Object, default: Object },
    },
    data() {
        return {
            opcoes: {
                anosLetivos: [],
                cursos: [],
                idiomas: [],
                grupoTurmas: [],
                tipoContratos: [],
            },
            paginacao: { numeroDaPagina: 1, tamanhoDaPagina: 30 },
        };
    },
    mounted() {
        this.getCursos();
        this.getIdiomas();
        this.getTipoContratos();
        this.getAnosLetivo();
        this.getStatusContratos();
    },
    watch: {
        'dados.anoLetivoId': 'getCursos',
        'dados.cursoId': 'getGrupoTurma',
    },
    methods: {
        getCursos() {
            if (!this.dados?.anoLetivoId) {
                return;
            }
            this.$store.dispatch(START_LOADING);
            CursoService.obterPorAnoLetivo(this.dados.anoLetivoId)
                .then(({ data }) => {
                    data = data.map((row) => ({
                        ...row,
                        text: row.descricao,
                        value: row.id,
                    }));
                    this.opcoes.cursos = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        getGrupoTurma() {
            if (
                this.dados.anoLetivoId.length == 0 ||
                this.dados.cursoId.length == 0
            )
                return;

            this.$store.dispatch(START_LOADING);
            GrupoTurmaService.buscarPorCursoIdAnoLetivoId(
                this.dados.cursoId,
                this.dados.anoLetivoId
            )
                .then(({ data }) => {
                    data = data.map((row) => ({
                        ...row,
                        text: row.descricao,
                        value: row.id,
                    }));
                    this.opcoes.grupoTurmas = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        getIdiomas() {
            this.$store.dispatch(START_LOADING);
            EnumeradoresService.listar('idiomas')
                .then(({ data }) => {
                    data = data.map((row) => ({
                        ...row,
                        text: row.descricao,
                        value: row.id,
                    }));
                    this.opcoes.idiomas = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        getAnosLetivo() {
            this.$store.dispatch(START_LOADING);
            AnoLetivoService.listar(this.paginacao)
                .then(({ data }) => {
                    this.opcoes.anosLetivos = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        getTipoContratos() {
            this.$store.dispatch(START_LOADING);
            EnumeradoresService.listar('tipo-contratos')
                .then(({ data }) => {
                    data = data.map((row) => ({
                        ...row,
                        text: row.descricao,
                        value: row.id,
                    }));
                    this.opcoes.tipoContratos = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
        getStatusContratos() {
            this.$store.dispatch(START_LOADING);
            EnumeradoresService.listar('status-contrato')
                .then(({ data }) => {
                    this.statusContratos = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
      
    },
};
</script>
