<template>
    <div>
        <titulo-contrato />
        <b-tabs>
            <b-tab class="pt-3" :title="$t('FINANCEIRO.DADOS_GERAIS')" active>
                <dados-gerais :dados="dadosContrato" />
                <hr />

                <tabela-precos :dados="dadosContrato" />
                <hr />

                <tabela-descontos :dados="dadosContrato" />
                <hr />
                <p>{{ $t('FINANCEIRO.OBSERVACOES') }}</p>
                <b-card>
                    <p>{{ dadosContrato.observacao }}</p>
                </b-card>
            </b-tab>
            <b-tab class="pt-3" :title="$t('FINANCEIRO.SIMULACAO_PARCELAS')">
                <listagem-contas-a-receber :contasAReceber="contasAReceber" />
            </b-tab>
        </b-tabs>
        <hr />
        <b-row>
            <b-col class="d-flex justify-content-end">
                <b-button variant="info" class="mr-2" @click="baixarContrato">
                    {{ $t('FINANCEIRO.BAIXAR_CONTRATO') }}
                </b-button>
            </b-col>
        </b-row>
    </div>
</template>

<script>
// helpers & aux
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import pdf from '@/common/utils/pdf';

// services
import ContratoService from '@/common/services/contratos/contratos.service';
import ContasReceberService from '@/common/services/conta-receber/conta-receber.service';
import MatriculaService from '@/common/services/matricula/matricula.service';

// components
import TituloContrato from './components/TituloContrato';
import DadosGerais from './components/DadosGerais';
import TabelaPrecos from './components/TabelaPrecos';
import TabelaDescontos from './components/TabelaDescontos';
import ListagemContasAReceber from './components/ListagemContasAReceber';
// import { InputTextArea } from '@/components/inputs';

export default {
    components: {
        DadosGerais,
        TabelaPrecos,
        TituloContrato,
        TabelaDescontos,
        // InputTextArea,
        ListagemContasAReceber,
    },
    data() {
        return {
            dadosContrato: {
                nomeAluno: '',
                codigoMatricula: '',
                observacao: '',
            },
            contasAReceber: [],
            paginacao: {
                total: 0,
                numeroDaPagina: 1,
                tamanhoDaPagina: 10,
            },
        };
    },
    mounted() {
        this.getContratoPorId();
    },
    methods: {
        getContratoPorId() {
            this.$store.dispatch(START_LOADING);
            ContratoService.buscarPorId(this.$route.params.id)
                .then(({ data }) => {
                    this.getMatriculaPorId(data);
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                    this.pegarContasAReceber();
                });
        },

        getMatriculaPorId(contrato) {
            if (!contrato?.matriculaId) return;
            this.$store.dispatch(START_LOADING);
            MatriculaService.buscarMatricula(contrato.matriculaId)
                .then(({ data }) => {
                    this.dadosContrato = {
                        ...contrato,
                        codigoMatricula: data.codigoMatricula,
                        nomeAluno: data.nome,
                        idioma: data.idiomaId,
                        observacao: data.observacao,
                    };
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        pegarContasAReceber() {
            this.$store.dispatch(START_LOADING);
            ContasReceberService.obterPorFiltroLista({
                contratoId: this.$route.params.id,
            })
                .then(({ data }) => {
                    this.contasAReceber = data;
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },

        baixarContrato() {
            this.$store.dispatch(START_LOADING);

            ContratoService.baixarPDF(this.$route.params.id)
                .then(({ data }) => {
                    if (data.documento) {
                        //converte os bytes em array:
                        let bytes = pdf.base64ToArrayBuffer(data.documento);

                        // converte o array em blob
                        let blob = new Blob([bytes], {
                            type: 'application/pdf',
                        });

                        // faz download do PDF:
                        var link = document.createElement('a');
                        link.href = window.URL.createObjectURL(blob);
                        link.download = 'Contrato.pdf';
                        link.click();
                    }
                })
                .catch((err) => {
                    mensagem.showErrors(err);
                })
                .finally(() => {
                    this.$store.dispatch(STOP_LOADING);
                });
        },
    },
};
</script>

<style></style>
