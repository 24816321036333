<template>
    <div>
        <h5>{{ $t('FINANCEIRO.DESCONTO') }}</h5>
        <b-table
            :items="dados.descontos"
            :fields="campos"
            :empty-text="$t('GERAL.NENHUM_REGISTRO')"
            head-variant="light"
            show-empty
            responsive
            striped
            hover
        >
            <template #cell(valor)="item">
                {{
                    item.item.valor == 0
                        ? `${item.item.percentual}%`
                        : formatarReal(item.item.valor)
                }}
            </template>
        </b-table>
    </div>
</template>

<script>
// AUX & HELPES
import helpers from '@/common/utils/helpers';

export default {
    name: 'TabelaDescontosAdicionados',
    components: {},
    props: {
        dados: { type: Object, default: Object },
    },
    data() {
        return {
            campos: [
                {
                    key: 'descricao',
                    sortable: true,
                    label: this.$t('FINANCEIRO.DESCRICAO'),
                },
                {
                    key: 'valor',
                    sortable: true,
                    label: this.$t('FINANCEIRO.VALOR'),
                },
            ],
        };
    },

    methods: {
        // FUNCOES AUX
        formatarReal(value) {
            return helpers.formatarReal(value);
        },
    },
};
</script>
