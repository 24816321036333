import ApiService from "@/common/api/api.service";

let apiBasePath = 'Curso'
const CursoService = {
  // FUNÇÕES DE BUSCA:
  async listar() {
    let { data } = await ApiService.get(`${apiBasePath}`);
    return data;
  },
  async obterPorId(cursoId) {
    let { data } = await ApiService.get(`${apiBasePath}/${cursoId}`);
    return data;
  },
  async obterPorAnoLetivo(anoLetivoId) {
    let { data } = await ApiService.get(`${apiBasePath}/obter-por-anoLetivo?id=${anoLetivoId}`);
    return data;
  },
};

export default CursoService;