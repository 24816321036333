import ApiService from "@/common/api/api.service";
let apiBasePath = 'GrupoTurma';

const GrupoTurmaService = {
    async getGrupoTurmaPorId(id){
        let data = await ApiService.get(`GrupoTurma/${id}`)
        return data;
    },
    async buscarPorCursoIdAnoLetivoId(cursoId, anoLetivoId) {
        let { data } = await ApiService.get(`grupoturma/obter-por-curso-id?cursoId=${cursoId}&anoLetivoId=${anoLetivoId}`);
        return data;
    },
        async buscarPorId(id) {
        let { data } = await ApiService.get(`${apiBasePath}/${id}`);
        return data;
    }
}

export default GrupoTurmaService;