<template>
    <topbar
        :titulo="$t('FINANCEIRO.CONTRATO')"
        :subtitulo="$t('FINANCEIRO.CONTRATO_VISUALIZAR_DESCRICAO')"
        :breadcrumbs="breadcrumbs"
    >
        <template #header>
            <b-button class="w-100" variant="secondary" @click="irPara">
                {{ $t('GERAL.VOLTAR') }}
            </b-button>
        </template>
    </topbar>
</template>
<script>
// Utils & Aux:
import rotas from '@/common/utils/rotas';
import Topbar from '@/layouts/full-layout/horizontal-sidebar/Topbar.vue';

// Components:

export default {
    components: { Topbar },
    data() {
        return {
            breadcrumbs: [
                { titulo: this.$t('FINANCEIRO.FINANCEIRO') },
                { titulo: this.$t('FINANCEIRO.MEUS_CONTRATOS') },
                { titulo: this.$t('GERAL.VISUALIZAR') },
            ],
        };
    },
    methods: {
        // FUNÇÕES CRUD:
        // cadastrar() {
        //     this.$emit('cadastrar', 'disciplina');
        // },
        // FUNÇÕES AUXILIARES:
        irPara() {
            rotas.retornar();
        },
    },
};
</script>
