<template>
    <div>
        <h5>{{ $t('FINANCEIRO.PRECOS') }}</h5>
        <b-table
            :items="dados.precos"
            :fields="campos"
            head-variant="light"
            :empty-text="$t('GERAL.NENHUM_REGISTRO')"
            stacked="md"
            responsive
            show-empty
            striped
            hover
        >
            <template #cell(origem)="origem">
                {{ origem.item.aditivoContratoId ? 'Aditivo' : 'Contrato' }}
            </template>
        </b-table>
    </div>
</template>

<script>
import helpers from '@/common/utils/helpers';

export default {
    name: 'TabelaPrecos',
    components: {},
    props: {
        dados: { type: Object, default: Object },
    },

    data() {
        return {
            campos: [
                {
                    key: 'descricao',
                    label: this.$t('FINANCEIRO.DESCRICAO'),
                },
                {
                    key: 'valorBase',
                    label: this.$t('FINANCEIRO.VALOR'),
                    formatter: (value) => {
                        return value ? helpers.formatarReal(value) : '-';
                    },
                },
                {
                    key: 'formaPagamentoSelecionadaDescricao',
                    label: this.$t('FINANCEIRO.FORMAS_PAGAMENTO'),
                },
                {
                    key: 'origem',
                    label: this.$t('FINANCEIRO.ORIGEM'),
                },
            ],
        };
    },
};
</script>
